<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-row class="top_l" :gutter="10">
					<el-col :span="6" :xs="24"><el-input v-model="userId" clearable placeholder="请输入userId" @clear="clearclick"></el-input></el-col>
					<el-col :span="2" :xs="24"><el-button type="primary" @click="jouShare">搜索</el-button></el-col>
				</el-row>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" style="width: 100%" border :row-style="TableRowStyle">
					<el-table-column prop="userId" label="userId" width="80" align="center"></el-table-column>
					<el-table-column label="金额">
						<template v-slot="scope">
							<span v-if="scope.row.type == 0">
								<span v-if="scope.row.fundCode.slice(0, 1) == 1">+{{ scope.row.money }}</span>
								<span v-else>-{{ scope.row.money }}</span>
							</span>
							<span v-else>{{ scope.row.money }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="balance" label="余额"></el-table-column>
					<el-table-column label="金币">
						<template v-slot="scope">
							<span v-if="scope.row.type == 1">
								<span v-if="scope.row.fundCode.slice(0, 1) == 1">+{{ scope.row.gold }}</span>
								<span v-else>-{{ scope.row.gold }}</span>
							</span>
							<span v-else>{{ scope.row.gold }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="goldBalance" label="金币余额"></el-table-column>
					<el-table-column label="元宝">
						<template v-slot="scope">
							<span v-if="scope.row.type == 2">
								<span v-if="scope.row.fundCode.slice(0, 1) == 1">+{{ scope.row.ingot }}</span>
								<span v-else>-{{ scope.row.ingot }}</span>
							</span>
							<span v-else>{{ scope.row.ingot }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="ingotBalance" label="元宝余额"></el-table-column>

					<el-table-column prop="fundName" label="描述" width="400"></el-table-column>
					<el-table-column prop="remark" label="备注" width="400"></el-table-column>
					<el-table-column prop="createTime" label="创建时间" width="170"></el-table-column>
					<el-table-column prop="updateTime" label="更新时间" width="170"></el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
				<!-- 分页 -->
				<div class="pagin">
					<el-pagination
						:small="true"
						v-model:currentPage="currentPage"
						v-model:page-size="pageSize"
						:page-sizes="[10, 20, 50, 100]"
						background
						layout="total, sizes, prev, pager, next, jumper"
						:total="+totals"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
					/>
				</div>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'tradeFundDetail',
	data() {
		return {
			emptyText: '暂无数据',
			currentPage: 1,
			pageSize: 10,
			tableData: [],
			totals: null,
			serloading: false,
			productId: '',
			userId: ''
		};
	},
	created() {
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		this.userId = this.$TOOL.data.get('USERID');
		// 获取账户明细
		this.tradeFundDetail();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 获取账户明细
				this.tradeFundDetail();
			}
		},
		'$store.state.userid'(newval) {
			if (this.userId != newval) {
				this.userId = newval;
				// 获取账户明细
				this.tradeFundDetail();
			}
		}
	},
	methods: {
		// 当状态为 已辍学 的状态，加上背景色
		TableRowStyle({ row }) {
			// 注意，这里返回的是一个对象
			let rowBackground = {};
			if (row.fundCode.slice(0, 1) == 1) {
				rowBackground.background = 'rgba(37,156,4,.3)';
			} else {
				rowBackground.background = 'rgba(255,0,0,.3)';
			}
			return rowBackground;
		},
		// 获取账户明细
		tradeFundDetail() {
			this.serloading = true;
			this.$HTTP.post(
				'tradeFundDetail/query',
				{
					productId: this.productId,
					pageNumber: this.currentPage,
					pageSize: this.pageSize,
					userId: this.userId
				},
				(response) => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data.records;
							this.totals = response.data.data.total;
							this.serloading = false;
							// console.log(this.tableData);
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 搜索
		jouShare() {
			this.currentPage = 1;
			// 获取账户明细
			this.tradeFundDetail();
		},
		// input 清空
		clearclick() {
			this.$TOOL.data.remove('USERID');
		},
		// 分页
		handleSizeChange(val) {
			this.currentPage = 1;
			this.pageSize = val;
			// 获取账户明细
			this.tradeFundDetail();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			// 获取账户明细
			this.tradeFundDetail();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.readbox {
	display: flex;
	align-items: center;
	span {
		margin-right: 10px;
		font-size: 16px;
	}
}
</style>
